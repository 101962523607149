.eeh-navigation-navbar .navbar-right {
  margin-right: 0; }

.eeh-navigation-sidebar {
  border-radius: 0;
  border-top: none;
  border-right: none;
  border-left: none; }
  .eeh-navigation-sidebar > .navbar-collapse {
    padding-right: 0;
    padding-left: 0; }
    .eeh-navigation-sidebar > .navbar-collapse > ul.sidebar-nav {
      /* Indent second level menu items */
      /* Indent third level menu items */ }
      .eeh-navigation-sidebar > .navbar-collapse > ul.sidebar-nav > li > ul > li > a {
        padding-left: 30px; }
      .eeh-navigation-sidebar > .navbar-collapse > ul.sidebar-nav > li > ul > li > ul > li > a {
        padding-left: 45px; }
  .eeh-navigation-sidebar .navbar-default > .active {
    background-color: #e7e7e7;
    color: #e7e7e7; }
  .eeh-navigation-sidebar .sidebar-search .sidebar-search-input a {
    position: relative;
    display: block;
    padding: 10px 15px; }
  .eeh-navigation-sidebar .sidebar-arrow {
    line-height: 1.5em; }
  .eeh-navigation-sidebar .sidebar-nav > li a {
    color: #777; }
    .eeh-navigation-sidebar .sidebar-nav > li a:hover, .eeh-navigation-sidebar .sidebar-nav > li a:focus {
      color: #333;
      background-color: transparent; }
  .eeh-navigation-sidebar .sidebar-nav > .active > a, .eeh-navigation-sidebar .sidebar-nav > .active > a:hover, .eeh-navigation-sidebar .sidebar-nav > .active > a:focus {
    color: #555;
    background-color: #e7e7e7; }
  .eeh-navigation-sidebar .sidebar-nav > .disabled > a, .eeh-navigation-sidebar .sidebar-nav > .disabled > a:hover, .eeh-navigation-sidebar .sidebar-nav > .disabled > a:focus {
    color: #ccc;
    background-color: transparent; }

@media (min-width: 768px) {
  .eeh-navigation-sidebar {
    z-index: 1;
    position: absolute;
    width: 250px; }

  .eeh-navigation-sidebar.sidebar-text-collapsed {
    width: 50px; }

  #eeh-navigation-page-wrapper.sidebar-text-collapsed {
    margin-left: 50px; }

  .sidebar-nav-nested.text-collapsed {
    position: absolute;
    left: 51px;
    top: 0;
    width: 250px;
    background-color: #f8f8f8; }
    .sidebar-nav-nested.text-collapsed a {
      padding-left: 15px !important; }
    .sidebar-nav-nested.text-collapsed .sidebar-nav-nested.text-collapsed {
      position: relative;
      left: 0; }
      .sidebar-nav-nested.text-collapsed .sidebar-nav-nested.text-collapsed a {
        padding-left: 30px !important; } }
#eeh-navigation-page-wrapper {
  padding: 0 15px;
  min-height: 568px;
  background-color: #fff; }

#eeh-navigation-page-wrapper.sidebar-invisible {
  margin-left: 0;
  border-left: none; }

@media (min-width: 768px) {
  #eeh-navigation-page-wrapper {
    position: inherit;
    margin: 0 0 0 250px;
    padding: 0 30px;
    border-left: 1px solid #e7e7e7; } }
.search-input-popover .navbar-form {
  padding: 0;
  margin: 0; }

.eeh-navigation {
  margin-bottom: 0; }
  .eeh-navigation a {
    cursor: pointer; }
  .eeh-navigation .icon-fw {
    width: 1.28571429em;
    text-align: center; }

/*# sourceMappingURL=eeh-navigation.css.map */
